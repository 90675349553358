/* eslint-disable max-len */
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { getFeatureFlagsInitValuesFor } from 'shared/featureFlags';

import { AuthContextProvider } from '@/context/AuthContext';
import { CalendarProvider } from '@/context/CalendarContext';
import { ContactsFiltersProvider } from '@/context/ContactsFiltersContext';
import { DBSyncProvider } from '@/context/DBSyncContext';
import { EntriesFiltersProvider } from '@/context/EntriesFiltersContext';
import { EventInvitationContextProvider } from '@/context/EventInvitationContext';
import { FeatureFlagsProvider } from '@/context/FeatureFlagsContext';
import { FreshchatContextProvider } from '@/context/FreshchatContext';
import { HorsesFiltersProvider } from '@/context/HorsesFiltersContext';
import { ImagesContextProvider } from '@/context/ImagesContext';
import { InventoryFiltersProvider } from '@/context/InventoryFiltersContext';
import { InvoicesFiltersProvider } from '@/context/InvoicesFiltersContext';
import { InvoicingSystemContextProvider } from '@/context/InvoicingSystemContext';
import { LocalizationContextProvider } from '@/context/LocalizationContext';
import { MUIDatePickerLocalizationProvider } from '@/context/MUIDatePickerLocalizationProvider';
import { MUIThemeContextProvider } from '@/context/MUIThemeContext';
import { OrganisationIncomingInvitationContextProvider } from '@/context/OrganisationIncomingInvitationContext';
import { OrganisationsContextProvider } from '@/context/OrganisationsContext';
import { OrgUserContextProvider } from '@/context/OrgUserContext';
import { PageScrollableElementRefProvider } from '@/context/PageScrollableElementRefContext';
import { RSVPOverlayProvider } from '@/context/RSVPOverlayContext';
import { ShoppingListFiltersProvider } from '@/context/ShoppingListFiltersContext';
import { SnackbarContextProvider } from '@/context/SnackbarContext';
import { UserContextProvider } from '@/context/UserContext';
import i18next from '@/services/translations/config';

import { Props } from './types';

const defaultBetaFeatures = getFeatureFlagsInitValuesFor('web');

function ProvidersWrapper({ children }: Props) {
    return (
        <I18nextProvider i18n={i18next}>
            <MUIThemeContextProvider>
                <MUIDatePickerLocalizationProvider>
                    <FeatureFlagsProvider
                        defaultBetaFeatures={defaultBetaFeatures}
                    >
                        <SnackbarContextProvider>
                            <AuthContextProvider>
                                <ImagesContextProvider>
                                    <UserContextProvider>
                                        <DBSyncProvider>
                                            <FreshchatContextProvider>
                                                <PageScrollableElementRefProvider>
                                                    <LocalizationContextProvider>
                                                        <InvoicingSystemContextProvider>
                                                            <OrganisationsContextProvider>
                                                                <OrgUserContextProvider>
                                                                    <OrganisationIncomingInvitationContextProvider>
                                                                        <ShoppingListFiltersProvider>
                                                                            <ContactsFiltersProvider>
                                                                                <HorsesFiltersProvider>
                                                                                    <EntriesFiltersProvider>
                                                                                        <InvoicesFiltersProvider>
                                                                                            <InventoryFiltersProvider>
                                                                                                <EventInvitationContextProvider>
                                                                                                    <RSVPOverlayProvider>
                                                                                                        <CalendarProvider>
                                                                                                            {
                                                                                                                children
                                                                                                            }
                                                                                                        </CalendarProvider>
                                                                                                    </RSVPOverlayProvider>
                                                                                                </EventInvitationContextProvider>
                                                                                            </InventoryFiltersProvider>
                                                                                        </InvoicesFiltersProvider>
                                                                                    </EntriesFiltersProvider>
                                                                                </HorsesFiltersProvider>
                                                                            </ContactsFiltersProvider>
                                                                        </ShoppingListFiltersProvider>
                                                                    </OrganisationIncomingInvitationContextProvider>
                                                                </OrgUserContextProvider>
                                                            </OrganisationsContextProvider>
                                                        </InvoicingSystemContextProvider>
                                                    </LocalizationContextProvider>
                                                </PageScrollableElementRefProvider>
                                            </FreshchatContextProvider>
                                        </DBSyncProvider>
                                    </UserContextProvider>
                                </ImagesContextProvider>
                            </AuthContextProvider>
                        </SnackbarContextProvider>
                    </FeatureFlagsProvider>
                </MUIDatePickerLocalizationProvider>
            </MUIThemeContextProvider>
        </I18nextProvider>
    );
}

export default ProvidersWrapper;
