import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InvoicesAnalytics } from 'shared/analytics/invoices/invoices';
import { INVOICE_STATUS } from 'shared/constants/invoices/statuses';
import Invoice from 'shared/db/services/Invoice';
import OrganisationAccountings from 'shared/db/services/OrganisationAccountings';
import { InvoiceModel } from 'shared/types/Invoice';
import { OrganisationAccountingsModel } from 'shared/types/OrganisationAccountings';

import { BackLinkButton, ErrorState, RemoveEntityAlert } from '@/components';
import AddEditInvoiceModal from '@/components/AddEditInvoiceModal';
import TooltipSyncButton from '@/components/TooltipButton';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useInvoicingSystemContext } from '@/context/InvoicingSystemContext';
import { useDatabase } from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';

import InvoicingNotAvailableSection from '../InvoicesPage/components/InvoicingNotAvailableSection';

import InvoiceDetails from './components';

function InvoicePage() {
    const { ImagesService } = useImagesContext();
    const { isSyncInProgress } = useDBSyncContext();
    const { isInvoiceSystemEnabled } = useInvoicingSystemContext();
    const navigate = useNavigate();

    const db = useDatabase();
    const database = useMemo(() => db.getDatabase(), [db]);

    const { id } = useParams();

    const [invoice, setInvoice] = useState<InvoiceModel | null>(null);

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isRemoveAlertOpen, setIsRemoveAlertOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState(false);

    const openEditModal = useCallback(() => setIsEditModalOpen(true), []);
    const closeEditModal = useCallback(() => setIsEditModalOpen(false), []);

    const [accountings, setAccountings] =
        useState<OrganisationAccountingsModel | null>(null);

    const { isInvoicingAvailable } = useInvoicingSystemContext();

    const handleInvoiceRemoved = useCallback(
        () => navigate(ROUTE.invoices),
        [navigate],
    );

    const invoiceService = useMemo(
        () =>
            new Invoice({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const fetchInvoice = useCallback(async () => {
        setIsError(false);

        try {
            if (!id) {
                throw new Error();
            }

            const invoice = await invoiceService.getByID(id);
            setInvoice(invoice);
        } catch {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [id, invoiceService]);

    useEffect(() => {
        fetchInvoice();
    }, [fetchInvoice]);

    const fetchAccountings = useCallback(async () => {
        const accountingsService = new OrganisationAccountings({
            database,
            logDBAction: Logger.logRecordActivity,
        });

        const [accountingsData] = await accountingsService.get().fetch();

        setAccountings(accountingsData);
    }, [database]);

    useEffect(() => {
        fetchAccountings();
    }, [fetchAccountings]);

    const isDeleteButtonEnabled = useMemo(() => {
        return (
            (!accountings?.accountingProvider &&
                invoice?.status === INVOICE_STATUS.paid) ||
            (!accountings?.accountingProvider &&
                invoice?.status === INVOICE_STATUS.authorised) ||
            invoice?.status === INVOICE_STATUS.draft
        );
    }, [accountings, invoice?.status]);

    const openDeleteAlert = useCallback(() => setIsRemoveAlertOpen(true), []);
    const closeDeleteAlert = useCallback(() => setIsRemoveAlertOpen(false), []);

    const removeInvoice = useCallback(async () => {
        if (invoice) {
            try {
                await invoiceService.deleteByID(invoice.id);

                InvoicesAnalytics.logUserDeletedInvoice(
                    FirebaseAnalytics.logEvent,
                );

                Snackbar.showToastNotification({
                    message: t('SingleInvoice:alert:invoice_removed'),
                });

                navigate(ROUTE.invoices, { replace: true });
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        }
    }, [invoice, invoiceService, navigate]);

    if (
        !isInvoicingAvailable ||
        (isInvoiceSystemEnabled && invoice?.provider === 'internal')
    ) {
        return <InvoicingNotAvailableSection />;
    }

    return (
        <Box sx={{ pb: 10, minWidth: 400 }}>
            {isEditModalOpen && invoice ? (
                <AddEditInvoiceModal
                    close={closeEditModal}
                    onRemove={handleInvoiceRemoved}
                    invoice={invoice}
                    isEditMode
                />
            ) : null}
            <Box
                sx={{
                    mb: 3.25,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <BackLinkButton
                    text={t('SingleInvoice:button:back')}
                    to={ROUTE.invoices}
                    testID="InvoicePage-BackButton"
                />
                <Box sx={{ display: 'flex', gap: 1.5 }}>
                    {invoice?.status === INVOICE_STATUS.draft ? (
                        <Button
                            data-test-id="InvoicePage-EditButton"
                            sx={{ display: 'flex', gap: 1 }}
                            color="inherit"
                            size="small"
                            disabled={isLoading || isError}
                            onClick={openEditModal}
                        >
                            <EditIcon />
                            <Typography fontSize="small" fontWeight={700}>
                                {t('SingleInvoice:button:edit')}
                            </Typography>
                        </Button>
                    ) : undefined}

                    {isDeleteButtonEnabled ? (
                        <TooltipSyncButton
                            tooltip={t('AddEditModalHeader:button_tooltip', {
                                defaultValue:
                                    'Please wait for the synchronization to complete',
                            })}
                            disabled={isLoading || isError || isSyncInProgress}
                            showTooltip={isSyncInProgress}
                            testID="InvoicePage-DeleteButton"
                            sx={{ display: 'flex', gap: 1 }}
                            color="inherit"
                            onClick={openDeleteAlert}
                        >
                            <DeleteIcon />
                            <Typography fontSize="small" fontWeight={700}>
                                {t('SingleInvoice:button:delete')}
                            </Typography>
                        </TooltipSyncButton>
                    ) : undefined}
                </Box>
            </Box>
            {isError ? (
                <ErrorState
                    onRefreshClick={fetchInvoice}
                    testID="InvoicePage-ErrorState"
                />
            ) : (
                <InvoiceDetails
                    invoice={invoice}
                    refetchInvoice={fetchInvoice}
                    isLoading={isLoading}
                />
            )}
            <RemoveEntityAlert
                dialogText={t('SingleInvoice:alert:invoice_remove', {
                    ref: invoice?.reference,
                })}
                isOpen={isRemoveAlertOpen}
                onClose={closeDeleteAlert}
                onRemove={removeInvoice}
                testID="RemoveInvoiceAlert"
            />
        </Box>
    );
}

export default InvoicePage;
