import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Backdrop, Box, Button, Menu, MenuItem, Paper } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContactModel } from 'shared/types/Contacts';
import { EntryModel } from 'shared/types/Entries';
import { EventModel } from 'shared/types/Events';
import { HorseModel } from 'shared/types/Horses';
import { InvoiceModel } from 'shared/types/Invoice';
import { AddProductParams } from 'shared/types/Products';

import {
    CartIcon,
    ContactsIcon,
    EntriesIcon,
    HorseIcon,
    InventoryIcon,
    InvoiceIcon,
    ScheduleIcon,
} from '@/assets/svg';
import {
    AddEditContactModal,
    AddEditEntryModal,
    AddEditHorseModal,
    AddEditScheduleModal,
    AddShoppingListModal,
} from '@/components';
import AddEditInvoiceModal from '@/components/AddEditInvoiceModal';
import { useInvoicingSystemContext } from '@/context/InvoicingSystemContext';
import { useAddProductsToInventory } from '@/hooks/useAddProductsToInventory';
import { AddInventoryProductModal } from '@/pages/InventoryPage/components/AddInventoryProductModal';
import { ROUTE } from '@/router/routes';
import { ENTITY_COLOR } from '@/theme/colors';
import { getRoutePath } from '@/utils/router';

import type { Props } from './types';

const menuTransitionDuration = 300;

const menuTransitionClasses = 'transition ease-out duration-300';

enum Modals {
    AddEntry = 'add-entry',
    AddSchedule = 'add-schedule',
    AddHorse = 'add-horse',
    AddContact = 'add-contact',
    AddInvoice = 'add-invoice',
    AddShoppingList = 'add-shopping-list',
    AddProduct = 'add-product',
}

function AddNewButton({ isAdmin }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleAddProductsToInventory = useAddProductsToInventory();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [openedModalId, setOpenedModalId] = useState<Modals | null>(null);

    const isAddNewMenuOpen = Boolean(anchorEl);

    const [transitionMenu, setTransitionMenu] = useState(false);
    const [isClosingMenu, setIsClosingMenu] = useState(false);

    const { isInvoicingAvailable } = useInvoicingSystemContext();

    useLayoutEffect(() => {
        if (isAddNewMenuOpen) {
            setTimeout(() => setTransitionMenu(true));
        } else {
            setTransitionMenu(false);
        }
    }, [isAddNewMenuOpen]);

    const handleAddNewClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAddNewMenu = useCallback(() => {
        setIsClosingMenu(true);
        setTimeout(() => {
            setAnchorEl(null);
            setIsClosingMenu(false);
        }, menuTransitionDuration);
    }, []);

    const closeModal = () => {
        setOpenedModalId(null);
    };

    const openModal = useCallback(
        (modalId: Modals) => {
            handleCloseAddNewMenu();
            setOpenedModalId(modalId);
        },
        [handleCloseAddNewMenu],
    );

    const handleNewEntry = useCallback(
        (entry: EntryModel) => {
            const route = getRoutePath(ROUTE.entry, { id: entry.id });
            navigate(route);
        },
        [navigate],
    );

    const handleNewSchedule = useCallback(
        (event: EventModel) => {
            const route = getRoutePath(ROUTE.appointment, {
                id: event.id,
            });
            navigate(route);
        },
        [navigate],
    );

    const handleNewHorse = useCallback(
        (horse: HorseModel) => {
            const route = getRoutePath(ROUTE.horse, { id: horse.id });
            navigate(route);
        },
        [navigate],
    );
    const handleNewContact = useCallback(
        (contact: ContactModel) => {
            const route = getRoutePath(ROUTE.contact, { id: contact.id });
            navigate(route);
        },
        [navigate],
    );

    const handleNewInvoice = useCallback(
        (invoice: InvoiceModel) => {
            const route = getRoutePath(ROUTE.invoice, { id: invoice.id });
            navigate(route);
        },
        [navigate],
    );

    const handleNewProduct = useCallback(
        (products: AddProductParams[]) => {
            const route = getRoutePath(ROUTE.inventory);
            navigate(route);
            handleAddProductsToInventory(products);
        },
        [handleAddProductsToInventory, navigate],
    );

    const navigateToInvoicesFallback = useCallback(() => {
        navigate(ROUTE.invoices);
    }, [navigate]);

    const entities = useMemo(() => {
        const fixedButtons = [
            {
                icon: <EntriesIcon width={28} height={28} color="white" />,
                label: t('Navigation:fab_button:entry'),
                color: ENTITY_COLOR.entries,
                onPress: () => openModal(Modals.AddEntry),
            },
            {
                icon: <ScheduleIcon width={26} height={26} color="white" />,
                label: t('Navigation:fab_button:appointment'),
                color: ENTITY_COLOR.schedule,
                onPress: () => openModal(Modals.AddSchedule),
            },
            {
                icon: <HorseIcon width={28} height={28} color="white" />,
                label: t('Navigation:fab_button:horse'),
                color: ENTITY_COLOR.horses,
                onPress: () => openModal(Modals.AddHorse),
            },
            {
                icon: <ContactsIcon width={28} height={28} color="white" />,
                label: t('Navigation:fab_button:contact'),
                color: ENTITY_COLOR.contacts,
                onPress: () => openModal(Modals.AddContact),
            },
        ];

        if (isAdmin) {
            fixedButtons.push({
                icon: <InvoiceIcon width={28} height={28} color="white" />,
                label: t('Navigation:fab_button:invoice'),
                color: ENTITY_COLOR.invoices,
                onPress: isInvoicingAvailable
                    ? () => openModal(Modals.AddInvoice)
                    : navigateToInvoicesFallback,
            });
        }

        const restButtons = [
            {
                icon: <CartIcon width={28} height={28} color="white" />,
                label: t('Navigation:fab_button:shopping_list'),
                color: ENTITY_COLOR.shoppingLists,
                onPress: () => openModal(Modals.AddShoppingList),
            },
            {
                icon: <InventoryIcon width={28} height={28} color="white" />,
                label: t('Navigation:fab_button:product'),
                color: ENTITY_COLOR.inventory,
                onPress: () => openModal(Modals.AddProduct),
            },
        ];

        return fixedButtons.concat(restButtons);
    }, [
        openModal,
        t,
        isInvoicingAvailable,
        navigateToInvoicesFallback,
        isAdmin,
    ]);

    return (
        <>
            <Button
                id="add-new-button"
                aria-controls={isAddNewMenuOpen ? 'add-new-button' : undefined}
                aria-haspopup="true"
                aria-expanded={isAddNewMenuOpen ? 'true' : undefined}
                size="medium"
                sx={{
                    fontWeight: 600,
                    alignSelf: 'flex-end',
                    fontSize: 12,
                    py: 1.125,
                    position: 'relative',
                }}
                type="button"
                variant="contained"
                startIcon={
                    <AddCircleIcon
                        className={clsx(
                            menuTransitionClasses,
                            'z-[1] pointer-events-none',
                            {
                                'rotate-0': !transitionMenu || isClosingMenu,
                                'rotate-45': transitionMenu && !isClosingMenu,
                            },
                        )}
                    />
                }
                onClick={handleAddNewClick}
            >
                <Paper
                    className={clsx(
                        menuTransitionClasses,
                        'absolute w-full z-0 h-full origin-top top-0 bg-deepCerulean hover:bg-deepCerulean08',
                        {
                            'scale-100': !transitionMenu || isClosingMenu,
                            'scale-x-[1.7] scale-y-[8.5]':
                                transitionMenu && !isClosingMenu,
                        },
                    )}
                />
                <span
                    className={clsx(
                        menuTransitionClasses,
                        'z-[2] pointer-events-none',
                        {
                            'opacity-50': isAddNewMenuOpen && !isClosingMenu,
                            'opacity-100': isClosingMenu || !isAddNewMenuOpen,
                        },
                    )}
                >
                    {t('Navigation:fab_button:add_new_label')}
                </span>
            </Button>
            <Menu
                id="add-new-menu"
                aria-labelledby="add-new-menu"
                anchorEl={anchorEl}
                open={isAddNewMenuOpen}
                onClose={handleCloseAddNewMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    className:
                        'bg-transparent shadow-none ml-[-11px] mt-[-4px]',
                }}
                transitionDuration={0}
                MenuListProps={{ className: 'static' }}
            >
                {!isClosingMenu ? (
                    <Paper
                        className={clsx(
                            menuTransitionClasses,
                            'absolute w-full h-full origin-top top-0',
                            {
                                'scale-[0.6]': !transitionMenu,
                                'scale-100': transitionMenu,
                            },
                        )}
                    />
                ) : null}
                <Box className="flex w-full justify-center">
                    <Button
                        variant="text"
                        size="small"
                        sx={{ color: '#919191' }}
                        startIcon={
                            !isClosingMenu ? (
                                <CancelIcon
                                    htmlColor="#919191"
                                    className={clsx(
                                        menuTransitionClasses,
                                        'mr-[-6px] w-[20px] h-[20px]',
                                        {
                                            'rotate-45': !transitionMenu,
                                            'rotate-90': transitionMenu,
                                        },
                                    )}
                                />
                            ) : null
                        }
                        onClick={handleCloseAddNewMenu}
                    >
                        <span
                            className={clsx(menuTransitionClasses, {
                                'opacity-50': !transitionMenu,
                                'opacity-0': isClosingMenu,
                                'opacity-100': transitionMenu && !isClosingMenu,
                            })}
                        >
                            {t('Actions:close')}
                        </span>
                    </Button>
                </Box>
                {entities.map((entity) => (
                    <MenuItem
                        disableRipple
                        key={entity.label}
                        className={clsx(
                            menuTransitionClasses,
                            'hover:bg-transparent cursor-default',
                            {
                                'opacity-50': !transitionMenu,
                                'opacity-0': isClosingMenu,
                                'opacity-100': transitionMenu && !isClosingMenu,
                            },
                        )}
                    >
                        <Button
                            size="medium"
                            fullWidth
                            sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                height: 47,
                                pr: 8,
                                bgcolor: entity.color,
                                justifyContent: 'flex-start',
                                borderRadius: '5px',
                                '&:hover': { bgcolor: entity.color },
                            }}
                            type="button"
                            variant="contained"
                            startIcon={entity.icon}
                            onClick={entity.onPress}
                        >
                            {entity.label}
                        </Button>
                    </MenuItem>
                ))}
            </Menu>
            <Backdrop
                sx={{
                    zIndex: 99,
                    backgroundColor: 'rgba(0,0,0,0.1)',
                }}
                open={isAddNewMenuOpen && !isClosingMenu}
                onClick={handleCloseAddNewMenu}
                transitionDuration={menuTransitionDuration}
            />
            {openedModalId === Modals.AddEntry ? (
                <AddEditEntryModal close={closeModal} onSave={handleNewEntry} />
            ) : null}
            {openedModalId === Modals.AddSchedule ? (
                <AddEditScheduleModal
                    onClose={closeModal}
                    onSubmit={handleNewSchedule}
                    view="agenda"
                />
            ) : null}
            {openedModalId === Modals.AddHorse ? (
                <AddEditHorseModal
                    close={closeModal}
                    CreateContactModal={AddEditContactModal}
                    onSave={handleNewHorse}
                />
            ) : null}
            {openedModalId === Modals.AddContact ? (
                <AddEditContactModal
                    close={closeModal}
                    onSave={handleNewContact}
                    CreateHorseModal={AddEditHorseModal}
                />
            ) : null}
            {openedModalId === Modals.AddInvoice ? (
                <AddEditInvoiceModal
                    onSave={handleNewInvoice}
                    close={closeModal}
                />
            ) : null}
            {openedModalId === Modals.AddShoppingList ? (
                <AddShoppingListModal close={closeModal} />
            ) : null}
            {openedModalId === Modals.AddProduct ? (
                <AddInventoryProductModal
                    onMainAction={handleNewProduct}
                    onCancel={closeModal}
                />
            ) : null}
        </>
    );
}

export default AddNewButton;
