import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import {
    Box,
    Button,
    Skeleton,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InvoicesAnalytics } from 'shared/analytics/invoices';
import { CountryCode } from 'shared/constants/countries';
import { INVOICE_STATUS } from 'shared/constants/invoices/statuses';
import Invoice from 'shared/db/services/Invoice';
import {
    getInvoiceStatus,
    getInvoiceStatusColor,
} from 'shared/utils/invoicing';

import InvoiceStatusBadge from '@/components/InvoiceStatusBox';
import TooltipSyncButton from '@/components/TooltipButton';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useUserContext } from '@/context/UserContext';
import { useDatabase } from '@/hooks';
import { useRegion } from '@/hooks/useRegion';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { COLOR } from '@/theme/colors';
import { formatDate } from '@/utils/date';
import {
    calculateInvoicePriceSum,
    calculateInvoiceVATLabel,
    getOverdueColor,
} from '@/utils/invoicing';

import { Props } from './types';

const invoiceTitleStyle: SxProps<Theme> = {
    fontSize: 24,
    fontWeight: 700,
    color: COLOR.paleSky,
};

const invoiceInfoStyle: SxProps<Theme> = {
    fontSize: 13,
    fontWeight: 300,
    color: COLOR.paleSky,
};

function HeaderSection({
    invoice,
    contact,
    accountings,
    isLoading,
    invoicePrice,
    refetchInvoice,
}: Props) {
    const database = useDatabase();
    const { ImagesService } = useImagesContext();
    const { isSyncInProgress } = useDBSyncContext();
    const { userProfileData } = useUserContext();

    const [invoiceTotal, setInvoiceTotal] = useState<string | null>(null);

    const invoiceService = useMemo(
        () =>
            new Invoice({
                database: database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );
    const userRegion = useRegion();
    const currency = userRegion ? userRegion.currencySymbol : '$';
    const isUSUser = userRegion?.code === CountryCode.unitedStates;

    useEffect(() => {
        let active = true;

        async function calculateInvoiceTotal() {
            if (!invoice) {
                return undefined;
            }

            const total = calculateInvoicePriceSum({
                priceSum: invoicePrice,
                invoice,
                currency,
            });

            if (!active) {
                return;
            }
            setInvoiceTotal(total);
        }

        calculateInvoiceTotal();
        return () => {
            active = false;
        };
    }, [ImagesService, currency, database, invoice, invoicePrice]);

    const handleDeepLinkClick = useCallback(() => {
        if (!invoice || !invoice.deepLink) {
            return;
        }

        InvoicesAnalytics.logUserClickedViewInvoiceButton(
            FirebaseAnalytics.logEvent,
        );

        window.open(invoice.deepLink, '_blank', 'noopener noreferrer');
    }, [invoice]);

    const handleAuthoriseInvoice = useCallback(async () => {
        if (!invoice || !userProfileData) {
            return;
        }

        await invoiceService.authoriseInvoice({
            invoice,
            contact,
            hasAccountingProvider: !!accountings?.accountingProvider,
        });

        InvoicesAnalytics.logUserAuthorizedInvoice(FirebaseAnalytics.logEvent);

        refetchInvoice();
    }, [
        accountings?.accountingProvider,
        contact,
        invoice,
        invoiceService,
        refetchInvoice,
        userProfileData,
    ]);

    const handleSetToPaidInvoice = useCallback(async () => {
        if (!invoice || !userProfileData) {
            return;
        }

        await invoiceService.setInvoiceToPaid(invoice);

        InvoicesAnalytics.logUserSetInvoiceToPaid(FirebaseAnalytics.logEvent);

        refetchInvoice();
    }, [invoice, invoiceService, refetchInvoice, userProfileData]);

    const handleSendPress = useCallback(async () => {
        if (
            !invoice ||
            invoice.status === INVOICE_STATUS.draft ||
            !accountings?.accountingProvider ||
            !userProfileData
        ) {
            return;
        }

        InvoicesAnalytics.logUserClickedSendInvoiceButton(
            FirebaseAnalytics.logEvent,
        );

        await invoiceService.sendInvoice(invoice, accountings);
        refetchInvoice();
    }, [accountings, invoice, invoiceService, refetchInvoice, userProfileData]);

    const handleBump = useCallback(async () => {
        if (!invoice) {
            return;
        }
        await invoiceService.bumpRelatedEntities(invoice.id);
        refetchInvoice();
    }, [invoice, invoiceService, refetchInvoice]);

    const invoiceInformationElement = useMemo(() => {
        if (invoice?.createdAt && !isLoading) {
            const createdAt = formatDate(invoice.createdAt.toISOString());

            return (
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography
                        data-test-id="InvoicePage-Header-CreatedAt"
                        sx={invoiceInfoStyle}
                    >
                        {`${t('InvoicesPage:columns:created_at')} ${createdAt}`}
                    </Typography>
                    {accountings?.accountingProvider ? (
                        <>
                            <Typography sx={invoiceInfoStyle}>-</Typography>
                            <Typography sx={invoiceInfoStyle}>
                                {t('Invoicing:provider_sync', {
                                    defaultValue: 'Synced with provider',
                                })}
                                {': '}
                                {invoice.synced
                                    ? t('Actions:yes')
                                    : t('Actions:no')}
                            </Typography>
                        </>
                    ) : null}
                </Box>
            );
        }

        return <Skeleton width={300} variant="text" />;
    }, [
        accountings?.accountingProvider,
        invoice?.createdAt,
        invoice?.synced,
        isLoading,
    ]);

    const vatLabelElement = useMemo(() => {
        if (!invoice || isLoading) {
            return <Skeleton width={200} variant="text" />;
        }

        const vatLabel = calculateInvoiceVATLabel(invoice, isUSUser);

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography sx={invoiceInfoStyle}>{vatLabel}</Typography>
            </Box>
        );
    }, [invoice, isLoading, isUSUser]);

    const actionSection = useMemo(() => {
        return (
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                    data-test-id="InvoicePage-Header-MailButton"
                    color="inherit"
                    size="small"
                    disabled={
                        isLoading ||
                        !contact?.email ||
                        !accountings?.accountingProvider ||
                        invoice?.status === INVOICE_STATUS.draft ||
                        isSyncInProgress
                    }
                    onClick={handleSendPress}
                    startIcon={<EmailIcon />}
                >
                    <Typography fontSize="small" fontWeight={700}>
                        {invoice?.sentToContactTime
                            ? t('Invoicing:header:resend', {
                                  defaultValue: 'Resend',
                              })
                            : t('Invoicing:header:send', {
                                  defaultValue: 'Send',
                              })}
                    </Typography>
                </Button>
                <Button
                    data-test-id="InvoicePage-Header-InfoButton"
                    color="inherit"
                    size="small"
                    disabled={
                        isLoading ||
                        !accountings?.accountingProvider ||
                        !invoice?.synced
                    }
                    onClick={handleDeepLinkClick}
                    startIcon={<InfoIcon />}
                >
                    <Typography fontSize="small" fontWeight={700}>
                        {t('Invoicing:header:deeplink', {
                            defaultValue: 'View',
                        })}
                    </Typography>
                </Button>
            </Box>
        );
    }, [
        accountings?.accountingProvider,
        contact?.email,
        handleDeepLinkClick,
        handleSendPress,
        invoice,
        isLoading,
        isSyncInProgress,
    ]);

    const invoiceActionButton = useMemo(() => {
        if (!invoice || isLoading) {
            return <Skeleton width={150} variant="text" />;
        }

        let buttonText = t('Invoicing:no_action', {
            defaultValue: 'No pending actions',
        });
        let buttonFn;
        let noPendingActions = true;

        if (invoice.status === INVOICE_STATUS.draft) {
            buttonText =
                !!contact?.email && !!accountings?.accountingProvider
                    ? t('Invoicing:authorise_send')
                    : t('Invoicing:authorise');
            buttonFn = handleAuthoriseInvoice;
            noPendingActions = false;
        }

        if (invoice.status === INVOICE_STATUS.authorised) {
            if (!accountings?.accountingProvider) {
                buttonText = t('Invoicing:set_to_paid');
                buttonFn = handleSetToPaidInvoice;
                noPendingActions = false;
            } else {
                if (contact?.email) {
                    buttonText = invoice.sentToContactTime
                        ? t('Invoicing:resend')
                        : t('Invoicing:send');
                    buttonFn = handleSendPress;
                    noPendingActions = false;
                }
            }
        }

        if (invoice.amountValid === false) {
            buttonText = t('Invoicing:resync', { defaultValue: 'Sync again' });
            buttonFn = handleBump;
            noPendingActions = false;
        }

        return (
            <TooltipSyncButton
                disabled={isLoading || noPendingActions || isSyncInProgress}
                tooltip={t('AddEditModalHeader:button_tooltip', {
                    defaultValue:
                        'Please wait for the synchronization to complete',
                })}
                testID="InvoicePage-Header-InvoiceActionButton"
                color="inherit"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={buttonFn}
                showTooltip={isSyncInProgress}
            >
                <Typography fontSize="small" fontWeight={700}>
                    {buttonText}
                </Typography>
            </TooltipSyncButton>
        );
    }, [
        accountings?.accountingProvider,
        contact?.email,
        handleAuthoriseInvoice,
        handleBump,
        handleSendPress,
        handleSetToPaidInvoice,
        invoice,
        isLoading,
        isSyncInProgress,
    ]);

    const overdueElement = useMemo(() => {
        if (!invoice?.dueDateTime || !invoice?.status || isLoading) {
            return <Skeleton width={150} variant="text" />;
        }

        const color = getOverdueColor(invoice.dueDateTime);

        const dueDate = formatDate(invoice.dueDateTime);

        return (
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography
                    sx={{
                        fontSize: 13,
                        fontWeight: 400,
                    }}
                >
                    {`${t('Invoice:labels:dueDate')}:`}
                </Typography>
                <Typography
                    data-test-id="InvoicesPage-TableRow-Overdue"
                    sx={{
                        color,
                        fontSize: 13,
                        fontWeight: 400,
                    }}
                >
                    {dueDate}
                </Typography>
            </Box>
        );
    }, [invoice?.dueDateTime, invoice?.status, isLoading]);

    const invoiceStatusLabel =
        invoice &&
        getInvoiceStatus({
            hasAccountingProvider: !!accountings?.accountingProvider,
            translateFn: t,
            sentToContact: invoice?.sentToContact,
            sentToContactTime: invoice?.sentToContactTime,
            status: invoice?.status,
        });

    const statusColor =
        invoice !== null
            ? getInvoiceStatusColor(invoice.status, invoice.dueDateTime).color
            : undefined;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2,
                bgcolor: COLOR.transparentLightGrayBackground,
                borderRadius: '16px 16px 2px 2px',
                mb: 7.5,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 1,
                }}
            >
                {isLoading ? (
                    <Skeleton width={400} height={40} variant="text" />
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            data-test-id="InvoicePage-Header-Reference"
                            sx={invoiceTitleStyle}
                        >{`${t('Entities:invoice')} ${
                            invoice?.reference
                        }`}</Typography>
                        {invoice?.number ? (
                            <Typography
                                data-test-id="InvoicePage-Header-ExternalId"
                                sx={invoiceTitleStyle}
                            >{`(${invoice.number})`}</Typography>
                        ) : null}
                        <InvoiceStatusBadge
                            status={invoiceStatusLabel || ''}
                            color={statusColor}
                            testID="InvoicePage-Header-Status"
                        />
                    </Box>
                )}
                {isLoading ? (
                    <Skeleton width={100} height={40} variant="text" />
                ) : (
                    <Typography
                        data-test-id="InvoicePage-Header-Total"
                        sx={invoiceTitleStyle}
                    >
                        {invoiceTotal}
                    </Typography>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: 2,
                    }}
                >
                    {overdueElement}
                    {invoiceInformationElement}
                    {actionSection}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        mt: 2,
                    }}
                >
                    {vatLabelElement}
                    {invoiceActionButton}
                </Box>
            </Box>
        </Box>
    );
}

export default HeaderSection;
