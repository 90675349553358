import React from 'react';

import { useInvoicingSystemContext } from '@/context/InvoicingSystemContext';

function withInvoicingSystemContext<T>(Component: (props: T) => JSX.Element) {
    function ComponentWithFeatureFlags(props: T) {
        const invoicingSystemContext = useInvoicingSystemContext();

        return <Component {...invoicingSystemContext} {...props} />;
    }

    ComponentWithFeatureFlags.displayName = Component.name;

    return ComponentWithFeatureFlags;
}

export default withInvoicingSystemContext;
