import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { CountryCode } from 'shared/constants/countries';
import OrganisationAccountings from 'shared/db/services/OrganisationAccountings';
import { ExternalAccountingProviderType } from 'shared/types/Invoicing';

import { useDatabase } from '@/hooks';
import { useRegion } from '@/hooks/useRegion';
import Logger from '@/services/logger';

import { useFeatureFlagsContext } from './FeatureFlagsContext';

export type ContextValue = {
    isInvoicingAvailable: boolean;
    isInvoiceSystemEnabled: boolean;
};

export type Props = {
    children: React.ReactNode;
};

const InvoicingSystemContext = createContext<ContextValue>({
    isInvoicingAvailable: false,
    isInvoiceSystemEnabled: false,
});

const InvoicingSystemContextProvider = ({ children }: Props) => {
    const { invoiceSystemEnabled } = useFeatureFlagsContext();
    const [accountingProvider, setAccountingProvider] =
        useState<ExternalAccountingProviderType | null>();

    const db = useDatabase();
    const database = useMemo(() => db.getDatabase(), [db]);
    const region = useRegion();
    const isUSRegion = region?.code === CountryCode.unitedStates;
    const isInvoiceSystemEnabled = invoiceSystemEnabled || isUSRegion;

    const accountingsService = useMemo(
        () =>
            new OrganisationAccountings({
                database,
                logDBAction: Logger.logRecordActivity,
            }),
        [database],
    );

    useEffect(() => {
        accountingsService
            .get()
            .observeWithColumns(['accounting_provider'])
            .subscribe(([accounting]) => {
                setAccountingProvider(accounting?.accountingProvider);
            });
    }, [accountingsService]);
    return (
        <InvoicingSystemContext.Provider
            value={{
                isInvoicingAvailable:
                    !isInvoiceSystemEnabled || Boolean(accountingProvider),
                isInvoiceSystemEnabled,
            }}
        >
            {children}
        </InvoicingSystemContext.Provider>
    );
};

function useInvoicingSystemContext() {
    const context = useContext(InvoicingSystemContext);

    return context;
}

export { InvoicingSystemContextProvider, useInvoicingSystemContext };
