import { DateTime } from 'luxon';
import { CountryCode } from 'shared/constants/countries';
import { LOCALE } from 'shared/constants/localization/types';
import regionStore from 'shared/stores/userRegion';
import moment from 'shared/utils/moment';

import { getLocaleFromLocalStorage } from '@/services/localization';

export const isFutureDate = (date: string): boolean => {
    const given = moment(date, 'YYYY-MM-DD');
    const now = moment();
    return given.diff(now) > 0;
};

export const overdueDaysCalculation = (date: string): number => {
    if (new Date(date).getTime() <= 0) return -1;

    const given = moment(date).startOf('day').utc();
    const current = moment().startOf('day').utc();

    // Difference in number of days
    const leftDays = given.diff(current, 'days');

    return isNaN(leftDays) ? -1 : Math.abs(leftDays);
};
export const formatDate = (date: string, format = 'date'): string => {
    const locale = getLocaleFromLocalStorage() || LOCALE.en_AU;
    const regionCode = regionStore.getRegion();
    const isUSRegion = regionCode === CountryCode.unitedStates;

    switch (format) {
        case 'date':
            if (isUSRegion) {
                return moment(date).format('MMMM Do YYYY');
            }
            return DateTime.fromISO(date).setLocale(locale).toLocaleString({
                year: 'numeric',
                month: 'short',
                day: '2-digit',
            });

        case 'datetime':
            if (isUSRegion) {
                return moment(date).format('MMMM Do YYYY hh:mm a');
            }

            return DateTime.fromISO(date).setLocale(locale).toLocaleString({
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            });

        case 'time':
            return DateTime.fromISO(date)
                .setLocale(locale)
                .toLocaleString({
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: locale !== LOCALE.nl_NL,
                });
        case 'month-date':
            return DateTime.fromISO(date).setLocale(locale).toLocaleString({
                month: 'short',
                day: '2-digit',
            });
        case 'month-year-short':
            return DateTime.fromISO(date).setLocale(locale).toLocaleString({
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            });
        default:
            return DateTime.fromISO(date).setLocale(locale).toLocaleString();
    }
};

const enOrdinalRules = new Intl.PluralRules('en-US', { type: 'ordinal' });

const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
]);

export function formatEnglishOrdinals(n) {
    const rule = enOrdinalRules.select(n);
    const suffix = suffixes.get(rule);
    return `${n}${suffix}`;
}
